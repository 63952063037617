.checkout {
    padding-top: $app-grid__column-gutter;
    select[disabled] {
        opacity: 0.5;
        &:hover {
            cursor: not-allowed;
        }
    }
    &__row{
        margin-bottom: $app-grid__column-gutter;
    }
    &__column{
        flex: 1 1 auto;
        padding: 0 $app-grid__column-gutter / 2;
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
        }
    }
    &__section{
        border-bottom: 1px solid #dddddd;
        padding: $app-grid__column-gutter / 2 0;
        opacity: .35;
        &:first-child{
            padding-top: 0;
        }
        &:last-child{
            border-bottom: none;
        }
        &--is-completed, &--is-active, {
            opacity: 1;
        }
    }
    /*
    &__progress-indicator{
        border-radius: 50%;
        border: 1px solid #7A5831;
        width: 35px;
        height: 35px;
        &--completed{
            background: #986E3D;
        }
        &--is-active{

        }
    }
    */
    &__progress-indicator{
        height: rem-calc($base__font-size * 2);
        width: rem-calc($base__font-size * 2);
        position: absolute;
        left: calc(50% - #{$base__font-size});

        align-items: center;
        display: flex;
        border-radius: 50%;
        font-weight: 700;
        justify-content: center;
        background: $base__light-gray-color;
        color: $base__white;
        border: 0.2em solid $base__white;
        z-index: 1;
        &--completed{
            background: $base__alt-success-color;
        }
        &--is-active{
            background-color: white;
            border-color: #89b87c;
            color: #89b87c;
        }
    }
    &__section__row{
        display: flex;
    }
    &__section__column{
        padding: 0 $app-grid__column-gutter / 2 0;
        flex: 0 1 auto;
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
        }
        &__header{
            display: flex;
            justify-content: space-between;
            &__column{
                &--title{

                }
                &--actions{

                }
            }
        }
        &--main-content{
            flex-grow: 1;
        }
        &--indicator{
            position: relative;
        }
    }
    &__section__heading{

    }
    &__section__label{

    }
    &__payment__method{
        &__title{
            font-weight: $base__font-weight--bold;
        }
    }
    &__payment__info__list{
        list-style: none;
        margin: 0;
    }
    &__address__line{
        margin-bottom: 0;
    }
    &__step__place-order__container{
        &--medium-up{
            display: none;
        }
    }
    &__cart{
        &__container{
            border: 1px solid #dddddd;
            border-radius: $base__border-radius;
        }
        &__row{
            padding: $app-grid__column-gutter / 3;
        }
        &__header{
            border-bottom: 1px solid #dddddd;
        }
        &__header__title{

        }
        &__line-items__title{

        }
        /* for cart line item approvals */
        &__line-item__document__actions{
            &__list{
                display: flex;
                list-style: none;
                margin: 0;
            }
            &__list-item{
                margin-right: rem-calc(8);
                &:last-child{
                    margin-right: 0;
                }
            }
            &__list-item__btn{
                padding-left: rem-calc(5);
                padding-right: rem-calc(5);
            }
            &__list__approve-document{
                display: flex;
                min-width: rem-calc(75);
                border: 1px solid $base__alert-color;
                border-radius: $base__border-radius;
            }
            &__approve-document__submit-btn{
                width: auto;
                flex: 0 0 auto;
                padding: 0;
                height: auto;
            }
        }
        &__summary__list,
        &__line-items__list,
        &__final-summary__list{
            display: table;
            border-collapse: collapse;
            list-style: none;
            margin: 0;
            width: 100%;
            border-bottom: 1px solid #dddddd;
        }
        &__final-summary__list-item,
        &__line-items__list-item{
            display: table-row;
            border-bottom: 1px solid #eeeeee;
            &:last-child{
                border-bottom: none;
            }
        }
        &__line-item--alert, &__line-item--alert:last-child {
            border: 2px solid $base__alert-color;
            border-radius: $base__border-radius;
        }
        &__final-summary__list-item__column,
        &__line-items__list-item__qty,
        &__line-items__list-item__sku,
        &__line-items__list-item__desc,
        &__line-items__list-item__name,
        &__line-items__list-item__total,
        &__summary__list-item__desc,
        &__summary__list-item__total{
            display: table-cell;
            padding: $app-grid__column-gutter / 4;
            vertical-align: top;
        }
        &__line-items__list-item__name{
            vertical-align: middle;
        }
        &__final-summary__row{
            padding: 0 $app-grid__column-gutter / 2;
        }
        &__final-summary__list-item__column,
        &__final-summary__grand-total{
            text-align: right;
            &__dollars{
                padding-left: $app-grid__column-gutter / 2;
                font-weight: $base__font-weight--bold;
            }
        }
        &__line-items__list-item__sku{
            white-space: nowrap;
            font-size: rem-calc(12);
        }
        &__summary__list-item__total,
        &__line-items__list-item__total{
            text-align: right;
        }
        &__summary__list{
            border-bottom: none;
        }
        &__summary__list-item{
            display: table-row;
            border-bottom: 1px solid #dddddd;
            &:last-child{
                border-bottom: none;
            }
        }
        &__summary{
            border-bottom: 1px solid #dddddd;
        }
        &__footer{
            &__row{
                display: table;
                border-collapse: collapse;
                width: 100%;
            }
            &__total,
            &__total__title{
                display: table-cell;
                padding: $app-grid__column-gutter / 4;
            }
            &__total{
                font-weight: $base__font-weight--bold;
                text-align: right;
            }
            &__total__title{

            }
        }
    }
}

@include mq(medium-up){
    .checkout{
        &__row{
            display: flex;
        }
        &__column{
            flex: 1 1 auto;
            &--steps{
                max-width: 40%;
            }
            &--cart{
                max-width: 60%;
            }
        }
        &__body-content{
            overflow: unset;
        }
        &__step__place-order__container{
            display: none;
            &--medium-up{
                display: block;
                padding: $app-grid__column-gutter / 2 0 0 0;
            }
        }
        &__cart{
            &__container{
                position: sticky;
                top: 0;
            }
        }
    }
}
